// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Comfortaa|Patua+One);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Import the google web fonts you want to use */\n\n/*Typography\n--------------------------------------------------------------*/\nbody,\nbutton,\ninput,\nselect,\ntextarea {\n  color: #404040;\n  font-family: \"Comfortaa\", Arial, sans-serif;\n  font-size: 14px;\n  line-height: 1.5;\n}\n\nbody {\n    padding: 0 3rem;\n}\n\nh1, h2, h3, h4, h5, h6 {\n  font-family: \"Patua One\", serif;\n  letter-spacing: 2px;\n}\n\np {\n  margin-bottom: 1.5em;\n}\n\n.title--main {\n  margin-block-end: 0;\n}\n\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,gDAAgD;;AAGhD;+DAC+D;AAC/D;;;;;EAKE,cAAc;EACd,2CAA2C;EAC3C,eAAe;EACf,gBAAgB;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;EACE,+BAA+B;EAC/B,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["/* Import the google web fonts you want to use */\n@import url(\"https://fonts.googleapis.com/css?family=Comfortaa|Patua+One\");\n\n/*Typography\n--------------------------------------------------------------*/\nbody,\nbutton,\ninput,\nselect,\ntextarea {\n  color: #404040;\n  font-family: \"Comfortaa\", Arial, sans-serif;\n  font-size: 14px;\n  line-height: 1.5;\n}\n\nbody {\n    padding: 0 3rem;\n}\n\nh1, h2, h3, h4, h5, h6 {\n  font-family: \"Patua One\", serif;\n  letter-spacing: 2px;\n}\n\np {\n  margin-bottom: 1.5em;\n}\n\n.title--main {\n  margin-block-end: 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
