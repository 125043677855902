import { FishGen } from "../aquarium/animations/animations"
import { NavBar } from "../nav/NavBar"



export const TankView = () => {
return (
    <>
    <NavBar />
    <FishGen />
</>
)
}